export type BroadcastToAppChannels =
  | 'JWT'
  | 'CLOSE'
  | 'ERROR'
  | 'LOGIN'
  | 'ROUTE_TO_PERSONAS';

export const broadcastToApp = (
  channel: BroadcastToAppChannels,
  message?: any
) => {
  //@ts-ignore
  if (!webkit.messageHandlers.cordova_iab) {
    console.warn('Cordova IAB postMessage API not found!');
  } else {
    //@ts-ignore
    webkit.messageHandlers.cordova_iab.postMessage(
      JSON.stringify({ channel: channel, content: message })
    );
  }
};
