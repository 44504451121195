import { Box, createSvgIcon, SvgIconProps } from '@mui/material';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import { Text } from '@vestwell-frontend/ui';

import { FC } from 'react';

export const AccountAccessIssueIcon: FC<SvgIconProps> = createSvgIcon(
  <svg
    fill='none'
    height='121'
    viewBox='0 0 120 121'
    width='120'
    xmlns='http://www.w3.org/2000/svg'>
    <rect
      height='58'
      rx='2'
      stroke='#DDC04A'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      width='103'
      x='9'
      y='31.5'
    />
    <path
      clipRule='evenodd'
      d='M12.25 37.5C12.25 35.9812 13.4812 34.75 15 34.75H106C107.519 34.75 108.75 35.9812 108.75 37.5V83.5C108.75 85.0188 107.519 86.25 106 86.25H15C13.4812 86.25 12.25 85.0188 12.25 83.5V37.5ZM15 36.25C14.3096 36.25 13.75 36.8096 13.75 37.5V83.5C13.75 84.1904 14.3096 84.75 15 84.75H106C106.69 84.75 107.25 84.1904 107.25 83.5V37.5C107.25 36.8096 106.69 36.25 106 36.25H15Z'
      fill='url(#paint0_linear_6582_12552)'
      fillRule='evenodd'
    />
    <path
      d='M93 98.5C93 78.6177 76.8823 62.5 57 62.5C37.1177 62.5 21 78.6177 21 98.5H93Z'
      fill='white'
    />
    <path
      d='M93 98.5C93 78.6177 76.8823 62.5 57 62.5C37.1177 62.5 21 78.6177 21 98.5H93Z'
      fill='url(#paint1_linear_6582_12552)'
    />
    <path
      d='M93 96.5C93 76.6177 76.8823 60.5 57 60.5C37.1177 60.5 21 76.6177 21 96.5'
      stroke='#033952'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
    <circle cx='57' cy='34.5' fill='white' r='20' />
    <circle cx='57' cy='34.5' fill='url(#paint2_linear_6582_12552)' r='20' />
    <circle cx='57' cy='32.5' r='20' stroke='#033952' strokeWidth='1.5' />
    <circle cx='91' cy='68.5' fill='#6E6E6E' r='14' />
    <rect fill='white' height='3' rx='1.5' width='3' x='89.5' y='72.5' />
    <path
      d='M88 65.5C88 63.8431 89.3431 62.5 91 62.5C92.6569 62.5 94 63.8431 94 65.5C94 66.6356 93.369 67.6239 92.4385 68.1333C91.7118 68.5311 91 69.1716 91 70V70.5'
      stroke='white'
      strokeLinecap='round'
      strokeWidth='2'
    />
    <defs>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint0_linear_6582_12552'
        x1='60.5'
        x2='60.5'
        y1='34.75'
        y2='86.25'>
        <stop stopColor='white' stopOpacity='0' />
        <stop offset='0.635417' stopColor='#2C8FC5' stopOpacity='0.09' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint1_linear_6582_12552'
        x1='57'
        x2='57'
        y1='62.5'
        y2='94.5'>
        <stop stopColor='#F5F9FF' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint2_linear_6582_12552'
        x1='57'
        x2='57'
        y1='14.5'
        y2='41'>
        <stop stopColor='#F5F9FF' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
    </defs>
  </svg>,
  'AccountAccessIssueIcon'
);

export function AccountAccessIssuePage() {
  useDocumentTitle('Account Access Issue');

  return (
    <Box mb={8} px={8}>
      <Box display='flex' justifyContent='center'>
        <AccountAccessIssueIcon sx={{ height: 120, width: 120 }} />
      </Box>
      <Text
        align='center'
        color='emphasize'
        data-testid='accountAccessIssueTitle'
        mb={8}
        variant='b2'>
        Account Access Issue
      </Text>
      <Text color='grey100' fontWeight={400} textAlign='center' variant='f2'>
        Your account is not yet available, or there was an issue linking your
        profile.
      </Text>
      <Text color='grey100' fontWeight={400} textAlign='center' variant='f2'>
        Contact your employer for assistance.
      </Text>
    </Box>
  );
}
