import {
  createTheme,
  inputClasses,
  Theme,
  ThemeOptions,
  ThemeProvider
} from '@mui/material';
import merge from '@mui/system/merge';
import { useGetWhiteLabel } from '@portal-middleware/hooks';

import { FC, memo, ReactNode, useMemo } from 'react';

import { VestwellPalette } from './global';

export type MaterialThemeProviderProps = {
  children: ReactNode;
  isWhitelabeled?: boolean;
  themeOverrides?: Partial<Theme>;
};

export const palette: VestwellPalette = {
  alert: {
    contrastText: '#FFFFFF',
    dark: '#D46E29',
    light: '#FFAD73',
    main: '#F58645'
  },
  aliceBlue: {
    contrastText: '#0D0400',
    dark: '#C4DDE8',
    light: '#FFFFFF',
    main: '#F2FBFF'
  },
  apple: {
    contrastText: '#FFFFFF',
    dark: '#C56767',
    light: '#F5ADAD',
    main: '#E68888'
  },
  ash: {
    contrastText: '#FFFFFF',
    dark: '#B8BEC8',
    light: '#F1F3F6',
    main: '#DBE0E7'
  },
  azureishWhite: {
    contrastText: '#241007',
    dark: '#A8C9E0',
    light: '#F2FBFF',
    main: '#DBEFF8'
  },
  black: {
    contrastText: '#FFFFFF',
    dark: '#000000',
    light: '#333333',
    main: '#000000' // no darker variant than black
  },
  blister: {
    contrastText: '#FFFFFF',
    dark: '#B58A00',
    light: '#F2CE4F',
    main: '#DEB304'
  },
  bloodOrange: {
    contrastText: '#FFFFFF',
    dark: '#C2510D',
    light: '#FF9050',
    main: '#EC6517'
  },
  blueIvy: {
    contrastText: '#FFFFFF',
    dark: '#1F75A1',
    light: '#65B3E0',
    main: '#3092CB'
  },
  blueSapphire: {
    contrastText: '#FFFFFF',
    dark: '#06405B',
    light: '#3578A6',
    main: '#0B5A80'
  },
  brandyButter: {
    contrastText: '#FFFFFF',
    dark: '#C78A61',
    light: '#F9D2B7',
    main: '#F4B98F'
  },
  cherry: {
    contrastText: '#FFFFFF',
    dark: '#991F34',
    light: '#E46A80',
    main: '#C52C48'
  },
  cornflowerBlue: {
    contrastText: '#FFFFFF',
    dark: '#5D49C7',
    light: '#A18FF8',
    main: '#7D62EC'
  },
  darkBlue: {
    contrastText: '#FFFFFF',
    dark: '#0B2444',
    light: '#446294',
    main: '#123561'
  },
  darkGold: {
    contrastText: '#FFFFFF',
    dark: '#5E4F14',
    light: '#A5964C',
    main: '#7C6A20'
  },
  denim: {
    contrastText: '#FFFFFF',
    dark: '#206F98',
    light: '#5DAEDF',
    main: '#2C8FC5'
  },
  dijon: {
    contrastText: '#FFFFFF',
    dark: '#B79D2A',
    light: '#EBD27C',
    main: '#DDC04A'
  },
  disabled: {
    contrastText: '#000000',
    dark: '#6E6E6E',
    light: '#BFBFBF',
    main: '#959595'
  },
  emphasize: {
    contrastText: '#FFFFFF',
    dark: '#012332',
    light: '#266A82',
    main: '#033952'
  },
  error: {
    contrastText: '#FFFFFF',
    dark: '#8B071E',
    light: '#D8425A',
    main: '#C00A2B'
  },
  ghostWhite: {
    contrastText: '#241007',
    dark: '#C8DBE3',
    light: '#FFFFFF',
    main: '#F5FBFF'
  },
  grannyApple: {
    contrastText: '#0D0400',
    dark: '#A3BDB7',
    light: '#D9F3E0',
    main: '#C8E6C9'
  },
  grey100: {
    contrastText: '#FFFFFF',
    dark: '#3A3A3A',
    light: '#7A7A7A',
    main: '#4F4F4F'
  },
  grey200: {
    contrastText: '#FFFFFF',
    dark: '#4E4E4E',
    light: '#8C8C8C',
    main: '#6B6B6B'
  },
  grey300: {
    contrastText: '#FFFFFF',
    dark: '#525252',
    light: '#A0A0A0',
    main: '#737373'
  },
  grey400: {
    contrastText: '#FFFFFF',
    dark: '#676767',
    light: '#B5B5B5',
    main: '#919191'
  },
  grey50: {
    contrastText: '#FFFFFF',
    dark: '#1A1A1A',
    light: '#565656',
    main: '#333333'
  },
  grey500: {
    contrastText: '#000000',
    dark: '#999999',
    light: '#E0E0E0',
    main: '#BDBDBD'
  },
  grey600: {
    contrastText: '#000000',
    dark: '#BDBDBD',
    light: '#F2F2F2',
    main: '#E0E0E0'
  },
  grey700: {
    contrastText: '#000000',
    dark: '#E0E0E0',
    light: '#FFFFFF',
    main: '#F2F2F2'
  },
  grey800: {
    contrastText: '#000000',
    dark: '#E2E2E2',
    light: '#FFFFFF',
    main: '#F5F5F5'
  },
  grey900: {
    contrastText: '#000000',
    dark: '#EDEDED',
    light: '#FFFFFF',
    main: '#FAFAFA'
  },
  imgPlaceholder: {
    contrastText: '#000000',
    dark: '#D9D9D9',
    light: '#FFFFFF',
    main: '#F5F5F5'
  },
  info: {
    contrastText: '#FFFFFF',
    dark: '#07537B',
    light: '#5BA2D3',
    main: '#0C78AB'
  },
  interact: {
    contrastText: '#FFFFFF',
    dark: '#005E90',
    light: '#4CA5CF',
    main: '#0679af'
  },
  khaki: {
    contrastText: '#000000',
    dark: '#B1AC97',
    light: '#E9E6DB',
    main: '#D9D4C1'
  },
  lightBlue: {
    contrastText: '#FFFFFF',
    dark: '#5193BA',
    light: '#A2D2F4',
    main: '#77B8E6'
  },
  lightGold: {
    contrastText: '#FFFFFF',
    dark: '#C3A954',
    light: '#F5E29C',
    main: '#E8D377'
  },
  lightKhaki: {
    contrastText: '#000000',
    dark: '#E0DED6',
    light: '#FFFFFF',
    main: '#F4F2ED'
  },
  lightestGray: {
    contrastText: '#FFFFFF',
    dark: '#6E6E6E',
    light: '#BFBFBF',
    main: '#959595'
  },
  limeGreen: {
    contrastText: '#FFFFFF',
    dark: '#207614',
    light: '#5FB14E',
    main: '#2CA01C'
  },
  mahogany: {
    contrastText: '#FFFFFF',
    dark: '#8E3100',
    light: '#D66029',
    main: '#B64200'
  },
  malibu: {
    contrastText: '#FFFFFF',
    dark: '#48A1D7',
    light: '#A0DEFF',
    main: '#72C6FE'
  },
  mediumBlue: {
    contrastText: '#FFFFFF',
    dark: '#265480',
    light: '#639AC6',
    main: '#3574A7'
  },
  mediumGold: {
    contrastText: '#FFFFFF',
    dark: '#988835',
    light: '#DEC778',
    main: '#C3AD4D'
  },
  metallicBlue: {
    contrastText: '#FFFFFF',
    dark: '#133847',
    light: '#4D7C95',
    main: '#1F5670'
  },
  mint: {
    contrastText: '#FFFFFF',
    dark: '#5AB399',
    light: '#B4F3DE',
    main: '#88E6C4'
  },
  muck: {
    contrastText: '#FFFFFF',
    dark: '#0A3E2B',
    light: '#3A8066',
    main: '#105B41'
  },
  niceBlue: {
    contrastText: '#FFFFFF',
    dark: '#04587F',
    light: '#46A6D4',
    main: '#0678AC'
  },
  oceanGreen: {
    contrastText: '#FFFFFF',
    dark: '#28A475',
    light: '#70E5B9',
    main: '#39CC97'
  },
  oxfordBlue: {
    contrastText: '#FFFFFF',
    dark: '#012535',
    light: '#305E75',
    main: '#033952'
  },
  page: {
    contrastText: '#000000',
    dark: '#E0E0E0',
    light: '#FFFFFF',
    main: '#FAFAFA'
  },
  paleSalmon: {
    contrastText: '#FFFFFF',
    dark: '#D89366',
    light: '#F7CEAE',
    main: '#F4B98F'
  },
  pictonBlue: {
    contrastText: '#FFFFFF',
    dark: '#3A87B5',
    light: '#77C4F0',
    main: '#53ACE3'
  },
  pine: {
    contrastText: '#FFFFFF',
    dark: '#2EB083',
    light: '#71E7C0',
    main: '#45DCA6'
  },
  placeholder: {
    contrastText: '#FFFFFF',
    dark: '#515151',
    light: '#8A8A8A',
    main: '#6B6B6B'
  },
  plantation: {
    contrastText: '#FFFFFF',
    dark: '#1D4135',
    light: '#4D7A6B',
    main: '#285849'
  },
  primary: {
    contrastText: '#FFFFFF',
    dark: '#005E90',
    light: '#3394C6',
    main: '#0679AF'
  },
  regalBlue: {
    contrastText: '#FFFFFF',
    dark: '#002C50',
    light: '#276691',
    main: '#004574'
  },
  ruby: {
    contrastText: '#FFFFFF',
    dark: '#F03456',
    light: '#F5768E',
    main: '#F2526F'
  },
  seasick: {
    contrastText: '#000000',
    dark: '#AEDFCC',
    light: '#F1FCF5',
    main: '#DEF9EA'
  },
  secondary: {
    contrastText: '#FFFFFF',
    dark: '#116743',
    light: '#46A07F',
    main: '#19855F'
  },
  sky: {
    contrastText: '#FFFFFF',
    dark: '#66A6D0',
    light: '#A6DBF1',
    main: '#88CAE6'
  },
  slate: {
    contrastText: '#FFFFFF',
    dark: '#526679',
    light: '#92A2B2',
    main: '#6F889C'
  },
  spinach: {
    contrastText: '#FFFFFF',
    dark: '#116743',
    light: '#46A07F',
    main: '#19855F'
  },
  success: {
    contrastText: '#FFFFFF',
    dark: '#06603F',
    light: '#3CA574',
    main: '#098056'
  },
  vividBurgundy: {
    contrastText: '#FFFFFF',
    dark: '#6E0F20',
    light: '#B54156',
    main: '#9A142C'
  },
  warning: {
    contrastText: '#FFFFFF',
    dark: '#993600',
    light: '#E07233',
    main: '#CB4A00'
  },
  water: {
    contrastText: '#1F5670',
    dark: '#A5D7E8',
    light: '#F1FAFF',
    main: '#DCF1FA'
  },
  wheat: {
    contrastText: '#FFFFFF',
    dark: '#C7B377',
    light: '#F2E6B0',
    main: '#EEDD93'
  },
  white: {
    contrastText: '#000000',
    dark: '#F0F0F0',
    light: '#FFFFFF',
    main: '#FFFFFF'
  },
  wintersDay: {
    contrastText: '#0D0400',
    dark: '#BEDDE8',
    light: '#F0FAFF',
    main: '#DFF4FF'
  }
};

export const theme = createTheme({
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          '&.Mui-expanded': {
            margin: 0
          },
          margin: 0
        }
      }
    },
    MuiAlert: {
      defaultProps: {
        'data-component': 'alert'
      },
      styleOverrides: {
        message: {
          '& a': {
            color: palette.black.main,
            textDecoration: 'underline'
          },
          '& button.MuiButton-inline': {
            color: palette.black.main,
            textDecoration: 'underline'
          }
        },
        root: {
          borderRadius: '7px',
          color: palette.grey50.main,
          fontSize: '0.875rem',
          lineHeight: 1.5,
          marginTop: '1rem',
          padding: '1rem auto',
          //the same as navbar
          zIndex: 120
        }
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        'data-component': 'autocomplete'
      },
      styleOverrides: {
        input: ({ theme }) => ({
          padding: theme.spacing(1, 2)
        })
      }
    },
    MuiAvatar: {
      defaultProps: {
        'data-component': 'avatar'
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 #0000, 0 0 #0000, 0px 0px 10px #72727226',
          height: 64
        }
      }
    },
    MuiBottomNavigationAction: {
      defaultProps: {
        focusRipple: false
      },
      styleOverrides: {
        root: {
          color: palette.black.main,
          height: '4rem',
          maxWidth: 'unset',
          minWidth: 'unset',
          padding: 0
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true
      },
      styleOverrides: {
        contained: {
          boxShadow: 'none',
          fontSize: '0.875rem',
          fontWeight: 700,
          lineHeight: '1.5',
          paddingLeft: '1rem',
          paddingRight: '1rem'
        },
        inline: {
          '&:hover': {
            backgroundColor: 'transparent'
          },
          fontWeight: 700,
          lineHeight: 'inherit',
          minWidth: 0,
          padding: 0,
          width: 'fit-content'
        },
        outlined: ({ ownerState, theme }) => ({
          border: `1px solid ${
            theme.palette[ownerState.color]?.main || theme.palette.primary.main
          }`,
          fontSize: '0.875rem',
          fontWeight: 700,
          lineHeight: '1.5',
          paddingLeft: '1rem',
          paddingRight: '1rem'
        }),
        root: ({ ownerState, theme }) => ({
          '& .MuiCircularProgress-root': {
            color: theme.palette.primary.main
          },
          '&.Mui-disabled': {
            backgroundColor: palette.grey700.main,
            borderColor: palette.grey600.main,
            color: palette.grey300.main
          },
          '&:focus-visible': {
            outline: `1px solid ${
              theme.palette[ownerState.color]?.main ||
              theme.palette.primary.main
            }`,
            outlineOffset: '2px'
          },
          borderRadius: '0.375rem',
          borderWidth: 1,
          fontSize: '0.875rem',
          fontWeight: 700,
          lineHeight: '1.5',
          marginBottom: 0,
          textTransform: 'unset'
        }),
        text: {
          fontWeight: 700,
          lineHeight: '1.5',
          paddingLeft: '1rem',
          paddingRight: '1rem'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        'data-component': 'buttonBase'
      },
      styleOverrides: {
        root: {
          '&.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px'
          }
        }
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        disableFocusRipple: true
      }
    },
    MuiCard: {
      defaultProps: {
        'data-component': 'card'
      },
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          padding: '16px'
        }
      }
    },
    MuiCardContent: {
      defaultProps: {
        'data-component': 'cardContent'
      },
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 0
          },
          padding: 0
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        'data-component': 'cardHeader'
      },
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        disableFocusRipple: true
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&.Mui-focusVisible': {
            outline: `1px solid ${
              theme.palette[ownerState.color]?.main ||
              theme.palette.primary.main
            }`,
            outlineOffset: '2px'
          },
          borderRadius: '5px',
          margin: '0 9px',
          padding: 0
        })
      }
    },
    MuiCssBaseline: {
      styleOverrides: theme => ({
        '.bg-primary': {
          backgroundColor: theme.palette.primary.main
        },
        '.border-primary': {
          borderColor: theme.palette.primary.main
        },
        //TODO: return to it once Form is refactored
        '.contents': {
          display: 'contents'
        },
        '.list-disc': {
          listStyleType: 'disc'
        },
        '.list-inside': {
          listStylePosition: 'inside'
        },
        '.list-outside': {
          listStylePosition: 'outside'
        },
        '.sr-only': {
          borderWidth: 0,
          clip: 'rect(0, 0, 0, 0)',
          height: '1px',
          margin: '-1px',
          overflow: 'hidden',
          padding: 0,
          position: 'absolute',
          whiteSpace: 'nowrap',
          width: '1px'
        },
        '.text-primary': {
          color: theme.palette.primary.main
        },
        'a:focus-visible': {
          borderRadius: '5px',
          outline: `1px solid ${theme.palette.primary.main}`,
          outlineOffset: '2px'
        },
        'a:not(.MuiButtonBase-root)': {
          fontWeight: 700
        }
      })
    },
    MuiDataGrid: {
      defaultProps: {
        'data-component': 'dataGrid'
      },
      styleOverrides: {
        cell: ({ theme }) => ({
          '&[aria-invalid="true"].MuiDataGrid-cell--editing:focus-within': {
            outline: 'none'
          },
          '&[aria-invalid="true"].MuiDataGrid-withBorderColor': {
            // todo:: find a way to better blend this into the palette
            backgroundColor: '#FEF8FF',
            border: `solid ${theme.palette.error.main} 1px`,
            outline: 'none'
          }
        }),
        editInputCell: ({ theme }) => ({
          '& input': {
            color: theme.palette.primary.main,
            fontSize: 'initial',
            padding: theme.spacing(0, 1)
          },
          height: '100%',
          margin: 0
        }),
        overlayWrapperInner: {
          display: 'flex',
          flexDirection: 'column'
        },
        root: ({ theme }) => ({
          '& .MuiDataGrid-cell': {
            alignItems: 'center',
            display: 'flex',
            fontSize: '1rem',
            lineHeight: 1.43,
            padding: '0.25rem'
          },
          '& .MuiDataGrid-cell--editable': {
            cursor: 'cell'
          },
          '& .MuiDataGrid-cell--editable > input': {
            padding: '0.25rem !important'
          },
          '& .MuiDataGrid-cell--textRight.MuiDataGrid-cell--editable > input': {
            textAlign: 'right'
          },
          '& .MuiDataGrid-columnHeader': {
            color: theme.palette.grey50.main,
            fontSize: '0.6875rem',
            fontWeight: 800,
            letterSpacing: '0.07em',
            lineHeight: 1.5,
            padding: '0 0.25rem',
            textTransform: 'uppercase'
          },
          '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
            width: 'auto'
          },
          '& .MuiDataGrid-columnHeader--alignRight': {
            textAlign: 'right'
          },
          '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
            {
              opacity: '1 !important'
            },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 800
          },
          '& .MuiDataGrid-columnHeaders': {
            height: '100%'
          },
          '& .MuiDataGrid-columnHeadersInner': {
            alignItems: 'center',
            height: 'inherit'
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '& .MuiDataGrid-container--top [role=row]': {
            backgroundColor: 'inherit'
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: theme.palette.ghostWhite.main
          },
          '& .MuiIconButton-root': {
            color: theme.palette.grey300.main
          },
          '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main
          },
          '& .MuiTablePagination-displayedRows': {
            color: theme.palette.grey100.main,
            fontSize: '1rem',
            marginBottom: 0
          },
          '& .MuiTablePagination-selectLabel': {
            color: theme.palette.grey100.main,
            fontSize: '1rem',
            marginBottom: 0
          },
          '&.MuiDataGrid-row--editing': {
            boxShadow: 'none'
          },
          border: 0,
          color: theme.palette.grey100.main
        })
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&:focus-visible': {
            outline: `1px solid ${
              theme.palette[ownerState.color]?.main ||
              theme.palette.primary.main
            }`,
            outlineOffset: '2px'
          }
        }),
        sizeSmall: {
          '& > svg': {
            height: '0.75em',
            width: '0.75em'
          },
          height: '0.75em',
          width: '0.75em'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          textAlign: 'inherit'
        },
        root: ({ ownerState, theme }) => ({
          '& input::placeholder': {
            color: theme.palette.grey300.main,
            opacity: 1
          },
          '&:after': {
            borderBottom: 0
          },
          '&:before': {
            borderBottom: `1px dashed ${
              ownerState['data-no-focus-style']
                ? 'transparent !important'
                : theme.palette[ownerState.disabled ? 'grey600' : 'grey300']
                    .main
            }`
          },
          [`label+&.${inputClasses.root}`]: {
            marginTop: 0
          },
          [`&:hover:not(.${inputClasses.disabled}, .${inputClasses.error}, .${inputClasses.focused}):before`]:
            {
              borderBottom: ownerState['data-no-focus-style']
                ? `1px dashed transparent !important`
                : `1px dashed ${theme.palette.primary.main}`
            },
          [`&.${inputClasses.focused}:hover:before`]: {
            borderBottom: 0
          },
          [`&.${inputClasses.focused}`]: {
            outline: ownerState['data-no-focus-style']
              ? 'none'
              : `1px solid ${theme.palette.primary.main}`
          },
          [`&.${inputClasses.focused}:before`]: {
            borderBottom: 0
          },
          [`&.${inputClasses.focused}:after`]: {
            borderBottom: 0
          },
          backgroundColor:
            theme.palette[ownerState.disabled ? 'grey800' : 'white'].main,
          borderRadius: theme.spacing(0.5),
          color:
            theme.palette[ownerState.disabled ? 'grey400' : 'primary'].main,
          marginBottom: 0,
          padding: theme.spacing(1, 2)
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.grey100.main,
          fontWeight: 700,
          marginBottom: 0,
          position: 'relative',
          transform: 'none',
          whiteSpace: 'inherit',
          zIndex: 0
        }
      }
    },
    MuiList: {
      defaultProps: {
        'data-component': 'list'
      }
    },
    MuiListItem: {
      defaultProps: {
        'data-component': 'listItem'
      }
    },
    MuiListItemButton: {
      defaultProps: {
        'data-component': 'listItemButton'
      }
    },
    MuiListSubheader: {
      defaultProps: {
        'data-component': 'listSubheader'
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            '&:focus': {
              backgroundColor: theme.palette.grey900.main
            },
            '&:hover': {
              backgroundColor: theme.palette.grey900.main
            },
            backgroundColor: theme.palette.white.main,
            color: theme.palette.primary.main,
            fontWeight: 700
          },
          '&:focus': {
            backgroundColor: theme.palette.grey900.main,
            borderLeft: `4px solid ${theme.palette.primary.main}`
          },
          '&:hover': {
            backgroundColor: theme.palette.grey900.main
          },
          backgroundColor: theme.palette.white.main,
          borderBottomColor: theme.palette.grey600.main,
          borderBottomStyle: 'dashed',
          borderBottomWidth: 1,
          borderLeft: '4px solid transparent',
          color: theme.palette.grey100.main,
          fontSize: '1rem',
          fontWeight: 400,
          lineHeight: '1.5',
          margin: 0,
          outline: 'none',
          padding: 8,
          userSelect: 'none'
        })
      }
    },
    MuiModal: {
      defaultProps: {
        'data-component': 'modal'
      }
    },
    MuiPaper: {
      defaultProps: {
        'data-component': 'paper'
      },
      styleOverrides: {
        elevation0: ({ theme }) => ({
          '&.MuiCard-root': {
            border: `${theme.spacing(0.25)} solid ${theme.palette.grey600.main}`
          }
        }),
        // todo:: temporary workaround until we can get a valid shadow list from ux
        elevation1: ({ theme }) => ({
          '&.MuiCard-root': {
            boxShadow: theme.shadows[24]
          }
        })
      }
    },
    MuiPopper: {
      defaultProps: {
        'data-component': 'popper'
      }
    },
    MuiRadio: {
      defaultProps: {
        disableFocusRipple: true
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&.Mui-focusVisible': {
            outline: `1px solid ${
              theme.palette[ownerState.color]?.main ||
              theme.palette.primary.main
            }`,
            outlineOffset: '2px'
          },
          padding: 0
        })
      }
    },
    MuiSkeleton: {
      defaultProps: {
        'data-component': 'skeleton'
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          height: '1.2em',
          width: '1.2em'
        },
        fontSizeSmall: {
          height: '0.85em',
          width: '0.85em'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          color: theme.palette.grey50.main,
          fontSize: '0.6875rem',
          fontWeight: 800,
          letterSpacing: '0.07em',
          lineHeight: 1.5,
          textTransform: 'uppercase'
        }),
        root: {
          '&[role="rowheader"]': {
            fontWeight: 600
          },
          ':first-of-type': {
            paddingLeft: '0.25rem'
          },
          ':last-of-type': {
            paddingRight: '0.25rem'
          },
          fontSize: '1rem',
          padding: '0.6875rem'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          ':last-child:not(.MuiTableRow-head) .MuiTableCell-root': {
            borderBottom: '1px solid transparent'
          }
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: '1.5',
          padding: '0.5rem 0.25rem',
          width: '3rem'
        }
      }
    },
    MuiToolbar: {
      defaultProps: {
        'data-component': 'toolbar'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTablePagination-actions': {
            color: theme.palette.grey100.main,
            fontSize: '1rem',
            marginBottom: 0
          },
          '& .MuiTablePagination-displayedRows': {
            color: theme.palette.grey100.main,
            fontSize: '1rem',
            marginBottom: 0
          },
          '& .MuiTablePagination-selectLabel': {
            color: theme.palette.grey100.main,
            fontSize: '1rem',
            marginBottom: 0
          }
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          '&::before': {
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)'
          },
          color: palette.white.main
        },
        tooltip: {
          backgroundColor: palette.white.main,
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
          color: palette.grey300.main,
          fontSize: '0.875rem',
          fontWeight: 400,
          padding: '0.75rem'
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        'data-component': 'text'
      }
    },
    Navbar: {
      styleOverrides: {
        desktop: {
          selected: 'rgb(250,250,250)',
          unselected: 'rgb(242,242,242)'
        },
        mobile: {
          selected: '#153850FF',
          unselected: 'rgb(250,250,250)'
        }
      }
    }
  },
  palette,
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(114, 114, 114, 0.15),0px 1px 1px 0px rgba(114, 114, 114, 0.15),0px 1px 3px 0px rgba(114, 114, 114, 0.15)',
    '0px 3px 3px -2px rgba(114, 114, 114, 0.15),0px 3px 4px 0px rgba(114, 114, 114, 0.15),0px 1px 8px 0px rgba(114, 114, 114, 0.15)',
    '0px 3px 1px -2px rgba(114, 114, 114, 0.15),0px 2px 2px 0px rgba(114, 114, 114, 0.15),0px 1px 5px 0px rgba(114, 114, 114, 0.15)',
    '0px 2px 4px -1px rgba(114, 114, 114, 0.15),0px 4px 5px 0px rgba(114, 114, 114, 0.15),0px 1px 10px 0px rgba(114, 114, 114, 0.15)',
    '0px 3px 5px -1px rgba(114, 114, 114, 0.15),0px 5px 8px 0px rgba(114, 114, 114, 0.15),0px 1px 14px 0px rgba(114, 114, 114, 0.15)',
    '0px 3px 5px -1px rgba(114, 114, 114, 0.15),0px 6px 10px 0px rgba(114, 114, 114, 0.15),0px 1px 18px 0px rgba(114, 114, 114, 0.15)',
    '0px 4px 5px -2px rgba(114, 114, 114, 0.15),0px 7px 10px 1px rgba(114, 114, 114, 0.15),0px 2px 16px 1px rgba(114, 114, 114, 0.15)',
    '0px 5px 5px -3px rgba(114, 114, 114, 0.15),0px 8px 10px 1px rgba(114, 114, 114, 0.15),0px 3px 14px 2px rgba(114, 114, 114, 0.15)',
    '0px 5px 6px -3px rgba(114, 114, 114, 0.15),0px 9px 12px 1px rgba(114, 114, 114, 0.15),0px 3px 16px 2px rgba(114, 114, 114, 0.15)',
    '0px 6px 6px -3px rgba(114, 114, 114, 0.15),0px 10px 14px 1px rgba(114, 114, 114, 0.15),0px 4px 18px 3px rgba(114, 114, 114, 0.15)',
    '0px 6px 7px -4px rgba(114, 114, 114, 0.15),0px 11px 15px 1px rgba(114, 114, 114, 0.15),0px 4px 20px 3px rgba(114, 114, 114, 0.15)',
    '0px 7px 8px -4px rgba(114, 114, 114, 0.15),0px 12px 17px 2px rgba(114, 114, 114, 0.15),0px 5px 22px 4px rgba(114, 114, 114, 0.15)',
    '0px 7px 8px -4px rgba(114, 114, 114, 0.15),0px 13px 19px 2px rgba(114, 114, 114, 0.15),0px 5px 24px 4px rgba(114, 114, 114, 0.15)',
    '0px 7px 9px -4px rgba(114, 114, 114, 0.15),0px 14px 21px 2px rgba(114, 114, 114, 0.15),0px 5px 26px 4px rgba(114, 114, 114, 0.15)',
    '0px 8px 9px -5px rgba(114, 114, 114, 0.15),0px 15px 22px 2px rgba(114, 114, 114, 0.15),0px 6px 28px 5px rgba(114, 114, 114, 0.15)',
    '0px 8px 10px -5px rgba(114, 114, 114, 0.15),0px 16px 24px 2px rgba(114, 114, 114, 0.15),0px 6px 30px 5px rgba(114, 114, 114, 0.15)',
    '0px 8px 11px -5px rgba(114, 114, 114, 0.15),0px 17px 26px 2px rgba(114, 114, 114, 0.15),0px 6px 32px 5px rgba(114, 114, 114, 0.15)',
    '0px 9px 11px -5px rgba(114, 114, 114, 0.15),0px 18px 28px 2px rgba(114, 114, 114, 0.15),0px 7px 34px 6px rgba(114, 114, 114, 0.15)',
    '0px 9px 12px -6px rgba(114, 114, 114, 0.15),0px 19px 29px 2px rgba(114, 114, 114, 0.15),0px 7px 36px 6px rgba(114, 114, 114, 0.15)',
    '0px 10px 13px -6px rgba(114, 114, 114, 0.15),0px 20px 31px 3px rgba(114, 114, 114, 0.15),0px 8px 38px 7px rgba(114, 114, 114, 0.15)',
    '0px 10px 13px -6px rgba(114, 114, 114, 0.15),0px 21px 33px 3px rgba(114, 114, 114, 0.15),0px 8px 40px 7px rgba(114, 114, 114, 0.15)',
    '0px 10px 14px -6px rgba(114, 114, 114, 0.15),0px 22px 35px 3px rgba(114, 114, 114, 0.15),0px 8px 42px 7px rgba(114, 114, 114, 0.15)',
    '0px 11px 14px -7px rgba(114, 114, 114, 0.15),0px 23px 36px 3px rgba(114, 114, 114, 0.15),0px 9px 44px 8px rgba(114, 114, 114, 0.15)',
    '0 1px 3px #00000026' // shadow which is in use for all cards
  ],
  spacing: 4,
  typography: {
    a1: {
      fontSize: '3rem',
      fontWeight: 300,
      lineHeight: 1.2
    },
    a2: {
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: 1.2
    },
    allVariants: {
      marginBottom: '1rem'
    },
    b1: {
      fontSize: '2.5rem',
      fontWeight: 300,
      letterSpacing: '0.025rem',
      lineHeight: 1.15
    },
    b2: {
      fontSize: '2.5rem',
      fontWeight: 600,
      letterSpacing: '0.025rem',
      lineHeight: 1.15
    },
    c1: {
      fontSize: '2.0625rem',
      fontWeight: 400,
      letterSpacing: '0.02063rem',
      lineHeight: 1.15
    },
    c2: {
      fontSize: '2.0625rem',
      fontWeight: 600,
      letterSpacing: '0.02063rem',
      lineHeight: 1.15
    },
    d1: {
      fontSize: '1.6875rem',
      fontWeight: 400,
      letterSpacing: '0.01688rem',
      lineHeight: 1.15
    },
    d2: {
      fontSize: '1.6875rem',
      fontWeight: 700,
      letterSpacing: '0.01688rem',
      lineHeight: 1.15
    },
    e1: {
      fontSize: '1.5rem',
      fontWeight: 400,
      letterSpacing: '0.015rem',
      lineHeight: 1.25
    },
    e2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      letterSpacing: '0.015rem',
      lineHeight: 1.25
    },
    f1: {
      fontSize: '1.1875rem',
      fontWeight: 400,
      letterSpacing: '0.01188rem',
      lineHeight: 1.4
    },
    f2: {
      fontSize: '1.1875rem',
      fontWeight: 700,
      letterSpacing: '0.01188rem',
      lineHeight: 1.4
    },
    fontFamily: [
      'Nunito Sans',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'Noto Sans,sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji'
    ].join(','),
    fontSize: 13,
    g1: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: '0.01rem',
      lineHeight: 1.36
    },
    g2: {
      fontSize: '1rem',
      fontWeight: 700,
      letterSpacing: '0.0075rem',
      lineHeight: 1.36
    },
    htmlFontSize: 13,
    i1: {
      fontFamily: 'Nunito Sans',
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: '0.00875rem',
      lineHeight: 1.4
    },
    i2: {
      fontFamily: 'Nunito Sans',
      fontSize: '0.875rem',
      fontWeight: 700,
      letterSpacing: '0.00875rem',
      lineHeight: 1.4
    },
    j1: {
      fontFamily: 'Nunito Sans',
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.0075rem',
      lineHeight: 1.4
    },
    j2: {
      fontFamily: 'Nunito Sans',
      fontSize: '0.75rem',
      fontWeight: 700,
      letterSpacing: '0.015rem',
      lineHeight: 1.4
    },
    k3: {
      fontSize: '0.6875rem',
      fontWeight: 800,
      letterSpacing: '0.055rem',
      lineHeight: 1.45,
      textTransform: 'uppercase'
    }
  }
} as ThemeOptions);

export const MaterialThemeProvider: FC<MaterialThemeProviderProps> = memo(
  ({ themeOverrides = {}, ...props }) => {
    const whitelabel = useGetWhiteLabel({
      query: {
        enabled: !!props.isWhitelabeled,
        keepPreviousData: true,
        useErrorBoundary: false
      }
    });

    const muiTheme = useMemo(
      () => ({
        ...merge(
          theme,
          !!props.isWhitelabeled && whitelabel.data?.theme
            ? whitelabel.data?.theme
            : themeOverrides
        )
      }),
      [whitelabel.data?.theme, props.isWhitelabeled, themeOverrides]
    );

    return <ThemeProvider theme={muiTheme}>{props.children}</ThemeProvider>;
  }
);

MaterialThemeProvider.displayName = 'MaterialThemeProvider';
