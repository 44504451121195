import {
  AdminPanelSettingsOutlined,
  ArrowDropDown,
  GppGoodOutlined,
  Logout,
  LoopOutlined
} from '@mui/icons-material';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useGetPersonas } from '@sentinel/hooks';
import { isEmbeddedBrowser, logout } from '@vestwell-frontend/helpers';
import { useSecureStorage, useSubdomain } from '@vestwell-frontend/hooks';

import Cookies from 'js-cookie';
import { FC, useCallback, useEffect, useId, useState } from 'react';
import { useLocation } from 'react-use';

import { useAuth } from './AuthProvider';

export const AccountMenu: FC = () => {
  const menuId = useId();

  const subdomain = useSubdomain();

  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);

  const [baseUrl] = useSecureStorage('BASE_API_URL');

  const [isEmbedded, setIsEmbedded] = useState(false);

  const { isNative } = useAuth();

  const open = Boolean(anchorEl);

  const personas = useGetPersonas(
    {
      disableDetails: true
    },
    {
      query: {
        enabled: location.pathname !== '/personas',
        keepPreviousData: true,
        suspense: true,
        useErrorBoundary: true
      }
    }
  );

  useEffect(() => {
    (async () => {
      const embeddedResult = await isEmbeddedBrowser();
      setIsEmbedded(embeddedResult);
    })();
  }, []);

  const onClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onLoginAndSecurity = useCallback(async () => {
    if (!isNative) {
      window.location.pathname = '/login-and-security';
    } else {
      window.location.assign(
        `${window.location.protocol}//${window.location.host}/auth/index.html?redirectUrl=/login-and-security?originPath=${window.location.pathname}`
      );
    }
  }, [isNative, baseUrl]);

  const onSwitchAccount = useCallback(async () => {
    if (!isNative) {
      Cookies.remove('isLoginDisclosure', {
        domain: `.${window.location.host.split('.').slice(-2).join('.')}`,
        path: '/'
      });

      window.location.pathname = '/personas';
    } else {
      window.location.assign(
        `${window.location.protocol}//${window.location.host}/auth/index.html?redirectUrl=/personas`
      );
    }
  }, [isNative, baseUrl]);

  return (
    <>
      <IconButton
        aria-controls={open ? menuId : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='Open Account Settings'
        data-testid='accountSettings'
        onClick={onClick}
        size='small'
        sx={{
          height: 'fit-content',
          width: 'fit-content'
        }}>
        <AdminPanelSettingsOutlined fontSize='large' />
        <ArrowDropDown />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        hideBackdrop
        id={menuId}
        onClick={onClose}
        onClose={onClose}
        open={open}>
        {subdomain !== 'abaretirement' && !isEmbedded && (
          <MenuItem onClick={onLoginAndSecurity}>
            <ListItemIcon>
              <GppGoodOutlined sx={{ fontSize: 24 }} />
            </ListItemIcon>
            Login & Security
          </MenuItem>
        )}
        {location.pathname !== '/personas' &&
          personas.data?.count > 1 &&
          !isEmbedded && (
            <MenuItem onClick={onSwitchAccount}>
              <ListItemIcon>
                <LoopOutlined sx={{ fontSize: 24 }} />
              </ListItemIcon>
              Switch Account
            </MenuItem>
          )}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout sx={{ fontSize: 24 }} />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
};
