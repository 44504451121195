import { Close } from '@mui/icons-material';
import {
  CircularProgress,
  Divider,
  IconButton,
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  type Palette,
  styled,
  useTheme
} from '@mui/material';

import {
  FC,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useId,
  useMemo
} from 'react';

import { ModalBody } from './ModalBody';
import { ModalContainer } from './ModalContainer';
import { ModalHeader } from './ModalHeader';

export type ModalProps = Pick<MuiModalProps, 'disableEnforceFocus' | 'ref'> & {
  /** Text content labeling the modal */
  'aria-label'?: string;
  /** ID of an element that labels the modal */
  'aria-labelledby'?: string;
  /** ID of an element with additional details about the modal */
  'aria-describedby'?: string;
  /** unique id; used for test selector */
  'data-testid'?: string;
  /** Text or component content */
  children: ReactNode;
  /** Display a close button */
  closeButton?: boolean;
  /** close confirmation dialog text */
  confirmMessage?: string;
  /** Text or component content for sticky header */
  header?: ReactNode;
  /** loading state of the modal */
  isLoading?: boolean;
  /** Determines the modal's visibility */
  isOpen?: boolean;
  /** sets width of modal for lg breakpoint */
  lgWidth?: number | string;
  /** Disable ability to close */
  locked?: boolean;
  /** sets width of modal for md breakpoint */
  mdWidth?: number | string;
  /** Handler function to close modal using keyboard  */
  onRequestClose(e?: SyntheticEvent): void;
  /** Make the user confirm before closing */
  requireConfirmOnClose?: boolean;
  /** adds scroll event handler to modal body */
  onScroll?: (e?: SyntheticEvent) => void;
  /** Aria role */
  role?: string;
  /** Color for backdrop */
  overlayColor?: keyof Palette;
};

const ModalLoader = styled('div', { name: 'modalLoader' })({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  minHeight: 350,
  width: '100%'
});

const StyledMuiModal = styled(MuiModal)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  left: 0,
  paddingBottom: `env(safe-area-inset-bottom)`,
  paddingTop: `env(safe-area-inset-top)`,
  position: 'fixed',
  right: 0,
  top: 0
});

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'hasHeader'
})<{ hasHeader: boolean }>(({ theme, hasHeader }) => ({
  color: theme.palette.black.main,
  position: 'absolute',
  right: hasHeader ? theme.spacing(8) : theme.spacing(2),
  top: hasHeader ? theme.spacing(4.5) : theme.spacing(2)
}));

export const Modal: FC<ModalProps> = ({
  confirmMessage = 'Your data is not saved yet. Are you sure you want to close the form?',
  ...props
}) => {
  const labelId = useId();

  const theme = useTheme();

  const ariaLabelledBy = useMemo(
    () => (typeof props.header === 'string' ? labelId : undefined),
    [labelId, props.header]
  );

  const handleClose = useCallback(() => {
    if (!props.requireConfirmOnClose) {
      return props.onRequestClose();
    }

    if (window.confirm(confirmMessage)) {
      props.onRequestClose();
    }
  }, [confirmMessage, props.onRequestClose, props.requireConfirmOnClose]);

  return (
    <StyledMuiModal
      onClose={props.locked ? undefined : handleClose}
      open={!!(props.locked || props.isOpen)}
      role='presentation'
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: props.overlayColor
              ? theme.palette[props.overlayColor]?.main
              : undefined
          }
        }
      }}>
      <ModalContainer
        aria-describedby={props['aria-describedby']}
        aria-label={props['aria-label']}
        aria-labelledby={props['aria-labelledby'] ?? ariaLabelledBy}
        data-component='modalContainer'
        data-testid={props['data-testid']}
        lgWidth={props.lgWidth}
        mdWidth={props.mdWidth}
        role={props.role || 'dialog'}>
        {!props.isLoading && props.header && (
          <>
            <ModalHeader id={ariaLabelledBy}>{props.header}</ModalHeader>
            <Divider />
          </>
        )}
        {props.isLoading ? (
          <ModalBody isRounded={!props.header}>
            <ModalLoader>
              <CircularProgress />
            </ModalLoader>
          </ModalBody>
        ) : (
          props.children
        )}
        {props.closeButton && (
          <StyledIconButton
            aria-label='close'
            hasHeader={!!props.header}
            onClick={handleClose}>
            <Close />
          </StyledIconButton>
        )}
      </ModalContainer>
    </StyledMuiModal>
  );
};

Modal.displayName = 'Modal';
