import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Redirect: FC<{ to: string }> = props => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(props.to);
  });

  return null;
};
