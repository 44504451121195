import { getBearerToken, isNativeMobile } from '@vestwell-frontend/helpers';

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';

export function useBearerToken(): [
  string | null,
  Dispatch<SetStateAction<string>>,
  boolean
] {
  const [bearerTokenValue, setBearerTokenValue] = useState<string | null>(null);
  const [isMobileApp, setIsMobileApp] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getBearerTokenValue = useCallback(async () => {
    try {
      const token = await getBearerToken(isMobileApp);
      setBearerTokenValue(token || null);
      setIsLoading(false);
    } catch (e) {
      console.warn(`Failed to get bearer token`, e);
      setIsLoading(false);
    }
  }, [isMobileApp]);

  useEffect(() => {
    isNativeMobile().then(setIsMobileApp);
  }, []);

  useEffect(() => {
    if (isMobileApp !== null) getBearerTokenValue();
  }, [getBearerTokenValue, isMobileApp]);

  useEffect(() => {
    const handleTokenChange = () => {
      getBearerTokenValue();
    };

    window.addEventListener('tokenChange', handleTokenChange);

    return () => {
      window.removeEventListener('tokenChange', handleTokenChange);
    };
  }, [getBearerTokenValue]);

  return [bearerTokenValue, setBearerTokenValue, isLoading];
}
