import { Redirect } from '@vestwell-frontend/ui';

import { FC } from 'react';
import { useLocation } from 'react-use';

export const AuthRedirectingPage: FC<unknown> = () => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const redirectUrl = urlParams.get('redirectUrl');

  let path = '/';

  if (redirectUrl) {
    path = `${redirectUrl}`;
  }

  return <Redirect to={path} />;
};
