import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { SemVer } from 'semver';

const compareToCurrentVersion = async versionTarget => {
  let capgoVersion;
  try {
    capgoVersion = await SecureStoragePlugin.get({
      key: 'CAPGO_BUNDLE'
    });
  } catch (e) {
    // @ts-ignore: if the version is requested from embedded browser, it will be in window, not secure storage
    capgoVersion = { value: window.CAPGO_BUNDLE };
  }

  try {
    const cutoffVersion = new SemVer(versionTarget);
    if (!capgoVersion.value) {
      console.warn('No CAPGO_BUNDLE found');
      return 0;
    } else {
      const currentVersion = new SemVer(capgoVersion.value);
      return currentVersion.compare(cutoffVersion);
    }
  } catch (e) {
    console.warn('Error getting CAPGO_BUNDLE', e);
  }
};

export const isMobileVersionHigherThan = async versionTarget => {
  try {
    const compareVersions = await compareToCurrentVersion(versionTarget);
    return compareVersions > 0;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const isMobileVersionLowerThan = async versionTarget => {
  try {
    const compareVersions = await compareToCurrentVersion(versionTarget);
    return compareVersions < 0;
  } catch (e) {
    console.error(e);
    return false;
  }
};
