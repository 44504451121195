import { useGetUserinfo, usePostToken } from '@sentinel/hooks';
import { useAuth } from '@vestwell-frontend/elements';
import {
  broadcastToApp,
  isEmbeddedBrowser,
  isMobileVersionHigherThan
} from '@vestwell-frontend/helpers';
import {
  useDocumentTitle,
  useNavigate,
  useSearchParams
} from '@vestwell-frontend/hooks';

import { useState } from 'react';
import { useMount, useUpdateEffect } from 'react-use';

import { routes } from '../router/routes';

export function AuthorizationPage() {
  useDocumentTitle('Authorization');

  const params = useSearchParams();

  const { setToken } = useAuth();
  const [isEmbedded, setIsEmbedded] = useState(false);
  const [isHigherVersion, setIsHigherVersion] = useState(null);

  const navigate = useNavigate();

  const postToken = usePostToken({
    mutation: {
      onSuccess: data => {
        setToken(data);
      }
    }
  });
  const userInfo = useGetUserinfo({
    query: {
      enabled: !!postToken.data?.access_token
    }
  });

  useMount(async () => {
    setToken('');
    const embedded = await isEmbeddedBrowser();
    setIsEmbedded(embedded);
    if (embedded) {
      const isHigher = await isMobileVersionHigherThan('4.0.0');
      setIsHigherVersion(isHigher);
    }
    await postToken.mutateAsync({
      //@ts-expect-error: TODO: fix types
      data: { code: params.code, grant_type: 'authorization_code' }
    });
  });

  useUpdateEffect(() => {
    if (userInfo.data && isEmbedded && isHigherVersion) {
      broadcastToApp('ROUTE_TO_PERSONAS', {
        message: 'Authorization complete'
      });
      return;
    }

    //@ts-expect-error TODO: fix types
    if (userInfo.data?.entity && (!isEmbedded || isHigherVersion !== null)) {
      navigate(routes.PERSONAS + '?redirect=true');
      return;
    }

    if (userInfo.data && (!isEmbedded || isHigherVersion !== null)) {
      navigate(routes.PERSONAS);
    }
  }, [userInfo.data, isEmbedded, isHigherVersion]);

  return null;
}

AuthorizationPage.displayName = 'AuthorizationPage';
