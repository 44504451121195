import { ArrowBack } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import {
  broadcastToApp,
  isEmbeddedBrowser,
  isNativeMobile
} from '@vestwell-frontend/helpers';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import { Button, Text } from '@vestwell-frontend/ui';

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

import {
  LoginSettingsSection,
  MfaSection,
  RecentLoginsSection
} from '../components';

const StyledButton = styled(Button)({
  alignSelf: 'center'
});

export function LoginAndSecurityPage() {
  useDocumentTitle('Login & Security');

  const [isEmbedded, setIsEmbedded] = useState(false);
  const [isNative, setIsNative] = useState(false);

  const { search } = useLocation();

  useEffect(() => {
    (async () => {
      const isEmbeddedBr = await isEmbeddedBrowser();
      setIsEmbedded(isEmbeddedBr);
      const isNativeApp = await isNativeMobile();
      setIsNative(isNativeApp);
    })();
  }, []);

  const navigate = useNavigate();

  const onBack = useCallback(() => {
    if (!isEmbedded && !isNative) {
      navigate(-1);
    } else if (!isEmbedded && isNative) {
      const urlParams = new URLSearchParams(search);
      const originPath = urlParams.get('originPath');
      const portal = /\/(.+)\//.exec(originPath);
      if (portal && portal.length) {
        window.location.assign(
          `${window.location.protocol}//${window.location.host}/${portal[1]}/index.html?redirectUrl=${originPath}`
        );
      } else {
        // Page accessed from persona selector
        window.location.assign(
          `${window.location.protocol}//${window.location.host}/auth/index.html?redirectUrl=${originPath}`
        );
      }
    } else {
      broadcastToApp('CLOSE');
    }
  }, [isEmbedded, isNative, search]);

  return (
    <Stack my={8} spacing={6}>
      <Stack>
        <StyledButton
          onClick={onBack}
          startIcon={<ArrowBack fontSize='small' />}
          variant='inline'>
          Back
        </StyledButton>
        <Text align='center' color='black' mb={6} variant='b2' width='100%'>
          Login & Security
        </Text>
      </Stack>
      <LoginSettingsSection />
      <MfaSection />
      <RecentLoginsSection />
    </Stack>
  );
}

LoginAndSecurityPage.displayName = 'LoginAndSecurityPage';
