export const routes = {
  ACCOUNT_ACCESS_ISSUE: '/sso/missing-account',
  ACCOUNT_LOCKED: '/locked',
  ACCOUNT_SETUP: '/setup/account',
  AUTHORIZATION: '/authorization',
  AUTH_REDIRECTING_PAGE_MOBILE: '/auth/index.html',
  EMPLOYER_REGISTRATION: '/register/employer',
  FORGOT_PASSWORD: '/password/forgot',
  LOGIN_AND_SECURITY: '/login-and-security',
  LOGIN_VERIFICATION: '/auth/api/v1/login/verification',
  LOGOUT: '/logout',
  MFA_NUDGE: '/mfa',
  MFA_VERIFICATION: '/verification',
  PERSONAS: '/personas',
  REGISTRATION: '/register',
  RESET_PASSWORD: '/password/reset',
  RESET_PASSWORD_EXPIRED: '/password/reset-expired',
  SIGN_IN: '/'
} as const;
